import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMainContext } from "../interface/interface";
import { MainContext } from "../common/context";

export default function Notice() {
  const navigate = useNavigate();
  const context = useContext<IMainContext>(MainContext);
  const [tabArr, setTabArr] = ["자주하는 질문", "1:1 상담"];
  const [activeTab, setActiveTab] = useState<string>("자주하는 질문");

  const handleClickTab = (str: string) => {
    setActiveTab(str);
  };
  return (
    <div className="container mx-auto sm:px-0 px-2 grid grid-cols-4 mt-12">
      {/* 마이페이지 헤더 */}
      <div className="">
        <p className="font-medium text-2xl">공지사항</p>

        <p
          onClick={() => handleClickTab("자주하는 질문")}
          className={`text-sm mt-4 ${
            activeTab === "자주하는 질문" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          자주하는 질문
        </p>
        <p
          onClick={() => handleClickTab("1:1 상담")}
          className={`text-sm mt-2 ${
            activeTab === "1:1 상담" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          1:1 상담
        </p>
      </div>
      <div className="grid col-span-3">
        <div>
          <div>
            {activeTab === "자주하는 질문" && (
              <p className="font-medium pb-10 text-xl border-b-black border-b-[2px]">자주하는 질문</p>
            )}
          </div>

          {activeTab === "자주하는 질문" && (
            <div className="mt-10 text-center">
              <p className="text-sm text-gray-500">아직 등록된 공지사항이 없습니다.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
