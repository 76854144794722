import React, { useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";

export const Modal = ({ onClose, children, modalContainerStyle, title }: any) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div>
      <div className="mask" onClick={onClose}></div>
      <div className="modal-body">
        <div className="content" style={{ ...modalContainerStyle }}>
          <div className="w-full flex justify-between items-center">
            <div className="flex-1"></div>
            <div className="flex-1 text-center">
              <p className="font-semibold text-lg">{title ? title : ""}</p>
            </div>
            <div className="flex-1 flex justify-end">
              <IoCloseOutline onClick={onClose} className="cursor-pointer" style={{ fontSize: 28 }} />
            </div>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
