import React, { useEffect, useState } from "react";

import { IMainContext } from "./interface/interface";
import { MainContext } from "./common/context";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./router/layout";
import Home from "./pages/home";
import Request from "./pages/request";
import TailwindSample from "./pages/design";
import Signin from "./pages/signin";
import Mypage from "./pages/mypage";
import Notice from "./pages/notice";
import ProductDetail from "./pages/productDetail";
import Buy from "./pages/buy";
import CartBuy from "./pages/cartBuy";

function App(): JSX.Element {
  const _handleStateChange = (key: string, state: any) => {
    setData((prevState: any) => {
      return {
        ...prevState,
        [key]: state,
      };
    });
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_COMPANYNAME);
  }, []);

  const [data, setData] = useState<IMainContext>({
    handleStateChange: _handleStateChange,
    isUser: false,
  });

  // if (!data.isUser) {
  //   return (
  //     <MainContext.Provider value={data}>
  //       <Routes>
  //         <Route path="/*" element={<Navigate to="/login"></Navigate>}></Route>
  //         <Route path="/login" index element={<Signin />} />
  //       </Routes>
  //     </MainContext.Provider>
  //   );
  // }

  return (
    <>
      <MainContext.Provider value={data}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/login" index element={<Signin />} />
            <Route path="/user/mypage" index element={<Mypage />} />
            <Route path="/user/cart/buy" index element={<CartBuy />} />
            <Route path="/product/:id" index element={<ProductDetail />} />
            <Route path="/buy/:id/:size" index element={<Buy />} />
            <Route path="/notice" index element={<Notice />} />
            <Route path="/design" index element={<TailwindSample />} />
          </Route>
        </Routes>
      </MainContext.Provider>
    </>
  );
}

export default App;
