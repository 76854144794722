import React, { useContext, useState } from "react";
import { MainContext } from "../common/context";
import { IMainContext } from "../interface/interface";
import { useNavigate } from "react-router-dom";
import { COMPANYNAME } from "../env";

export default function Signin(): JSX.Element {
  const context = useContext<IMainContext>(MainContext);
  const navigate = useNavigate();

  const [id, setId] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSignin = (): void => {
    if (id === "phh0912" && password === "uTY8RcQP245!") {
      context.handleStateChange("isUser", true);
      localStorage.setItem("isUser", "user");
      navigate("/");
    } else {
      alert("아이디와 비밀전호를 확인해주세요.");
    }
  };

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-3 md:grid-cols-1">
        <div className="md:col-start-1 col-start-2 mt-[40px]">
          <p className="text-center font-bold text-2xl">{COMPANYNAME}</p>
        </div>
      </div>
      <div className="grid grid-cols-3 xs:grid-cols-1">
        <div className="xs:col-start-1 col-start-2 mt-[40px]">
          <div>
            <input
              value={id}
              onChange={(e) => setId(e.target.value)}
              placeholder="아이디"
              className="bg-gray-50 w-full py-[10px] px-[10px] outline-none"
            />
          </div>
          <div>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="비밀번호"
              className="bg-gray-50 w-full py-[10px] px-[10px] outline-none mt-[12px]"
            />
          </div>

          <div>
            <button
              onClick={handleSignin}
              className="bg-black w-full rounded-md text-white mt-[16px] py-[14px] text-md"
            >
              로그인
            </button>
            {/* <input placeholder="비밀번호" className="bg-gray-50 w-full py-[8px] px-[10px] outline-none mt-[12px]" /> */}
          </div>
        </div>
      </div>
      {/* <div className="grid min-h-screen">
        <div className="flex items-center">
          <div className="w-full">
            <p className="text-gray-500 text-sm mb-1 mt-2">아이디</p>
            <input
              onChange={(e) => setId(e.target.value)}
              placeholder="아이디를 입력해주세요"
              autoComplete="off"
              type="text"
              id="default-input"
              className="text-md bg-gray-100 border border-gray-300 text-gray-700 focus:ring-blue-500 focus:border-blue-500 block w-full  p-3.5 pl-3 placeholder:text-sm"
            />

            <p className="text-gray-500 text-sm mb-1 mt-2">패스워드</p>
            <input
              onChange={(e) => setPassword(e.target.value)}
              placeholder="패스워드를 입력해주세요"
              autoComplete="new-password"
              type="password"
              id="default-input"
              className="text-md outline-none outline-2 bg-gray-100 border border-gray-300 text-gray-700 
                focus:border-blue-500
                focus:border-2 block w-full p-3.5 pl-3 placeholder:text-sm"
            />

            <p className="text-xs text-gray-500 text-right mt-1 mb-3">패스워드를 입력해주세요.</p>
            <button onClick={handleSignin} className="bg-blue-400 text-[#efefef] text-sm w-full h-11">
              로그인
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
}
