import React, { useContext, useEffect, useState } from "react";
import Modal from "../components/Modal";
import { useParams } from "react-router-dom";
import { currency } from "../method";
import { FaBoxOpen } from "react-icons/fa";
import { GoContainer } from "react-icons/go";
import { IoIosArrowDown } from "react-icons/io";
import { ModalPortal } from "../components/ModalPortal";
import { CiShoppingCart } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import PRODUCT_DATA from "../data/data";
import ProductCard from "../components/ProductCard";
import { IMainContext } from "../interface/interface";
import { MainContext } from "../common/context";

const SIZE_DATA = [
  {
    size: "225",
  },
  {
    size: "230",
  },
  {
    size: "235",
  },
  {
    size: "240",
  },
  {
    size: "245",
  },
  {
    size: "250",
  },
  {
    size: "255",
  },
  {
    size: "260",
  },
  {
    size: "265",
  },
  {
    size: "270",
  },
  {
    size: "275",
  },
  {
    size: "280",
  },
];

export default function ProductDetail() {
  const { id }: any = useParams();
  const context = useContext<IMainContext>(MainContext);
  const [data, setData] = useState<any>(PRODUCT_DATA);
  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState<string>(SIZE_DATA[0].size);
  const [detailData, setDetailData] = useState<any>(PRODUCT_DATA[id - 1]);
  const [targetImgIdx, setTargetImgIdx] = useState<number>(0);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleSetImg = (type: string) => {
    if (type === "prev" && targetImgIdx === 0) return;
    if (type === "next" && targetImgIdx === detailData?.images?.length - 1) return;
    if (type === "prev") {
      setTargetImgIdx((prev) => prev - 1);
    }
    if (type === "next") {
      setTargetImgIdx((prev) => prev + 1);
    }
  };

  const handleAddCart = () => {
    if (!context.isUser) return alert("로그인 후 카트를 이용해 주세요.");
    const cartForm: any = localStorage.getItem("cart");
    let parsedCart: any = cartForm ? JSON.parse(cartForm) : [];

    for (let i in parsedCart) {
      if (parsedCart[i].size === selectedSize && parsedCart[i].index === detailData.index) {
        alert("이미 카트에 담겨 있는 상품입니다.");
        return;
      }
    }

    parsedCart = [...parsedCart, { ...detailData, size: selectedSize }];

    localStorage.setItem("cart", JSON.stringify(parsedCart));
    alert("카트에 추가되었습니다.");
  };
  return (
    <div className="container mx-auto sm:px-0 px-2 ">
      <div className="grid md:grid-cols-2 gap-12 mt-14 border-b pb-10">
        <div>
          <div
            style={{ backgroundImage: `url(${detailData?.images[targetImgIdx]})` }}
            className="bg-cover bg-center h-[530px] bg-slate-50 rounded-sm flex justify-between items-center"
          >
            {targetImgIdx !== 0 && (
              <div onClick={() => handleSetImg("prev")} className="cursor-pointer">
                <IoIosArrowBack color="#979797" size={40} />
              </div>
            )}
            {targetImgIdx === 0 && <div className="w-[40px]"></div>}
            {targetImgIdx < detailData.images?.length - 1 && (
              <div onClick={() => handleSetImg("next")} className="cursor-pointer">
                <IoIosArrowForward color="#979797" size={40} />
              </div>
            )}
          </div>
        </div>
        <div>
          <p className="font-medium text-lg">{detailData.brand}</p>
          <p className="font-medium text-2xl mt-2">{detailData.productName}</p>
          <p className="text-sm font-light text-gray-400 mt-2">{detailData.productName}</p>
          <p className="font-medium text-sm mt-2">{currency(detailData.price)}원</p>

          <div
            onClick={() => setIsOpen(true)}
            className="w-full border border-black py-[10px] mt-6 rounded-md px-[10px] text-sm cursor-pointer"
          >
            <div className="flex items-center justify-between">
              <div className="font-semibold">사이즈</div>

              <div className="flex items-center">
                <div className="font-bold mr-4">{selectedSize}</div>
                <IoIosArrowDown style={{ fontSize: 16 }} className="mt-1" />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 mt-6">
            <button
              onClick={handleAddCart}
              className="bg-blue-500 rounded-md text-white py-[12px] flex items-center justify-center"
            >
              <CiShoppingCart style={{ fontSize: 22 }} className="mr-2" />
              카트에 넣기
            </button>
            <button
              onClick={() => {
                navigate(`/buy/${id}/${selectedSize}`);
              }}
              className="bg-red-400 rounded-md text-white py-[12px]"
            >
              <div className="grid grid-cols-3">
                <div className="col-span-2">
                  <p>{currency(detailData.price)}원</p>
                </div>
                <div className="cols-start-3 border-l border-gray-50">
                  <p className="text-white text-sm font-bold">즉시구매</p>
                </div>
              </div>
            </button>
          </div>

          <div className="grid grid-cols-2 py-[14px] border-b w-full mt-8">
            <div className="flex items-center">
              <FaBoxOpen style={{ fontSize: 20, color: "#979797" }} className="mr-[6px]" />
              <p className="text-sm font-semibold">
                <span className="font-light">일반 배송</span> 3,000원
              </p>
            </div>

            <p className="text-end text-gray-400 text-sm font-light">검수 후 배송, 5-7일 내 도착 예정</p>
          </div>

          <div className="grid grid-cols-2 py-[14px] border-b w-full">
            <div className="flex items-center">
              <GoContainer style={{ fontSize: 20, color: "#979797" }} className="mr-[6px]" />
              <p className="text-sm font-semibold">
                <span className="font-light">센터 보관</span> 첫 90일 무료
              </p>
            </div>

            <p className="text-end text-gray-400 text-sm font-light">배송없이 솔드아웃 센터에 보관</p>
          </div>
        </div>
        {isOpen && (
          <ModalPortal>
            <Modal onClose={handleCloseModal} modalContainerStyle={{ width: "40vw" }}>
              <div className="grid grid-cols-4 gap-2 mt-4">
                {SIZE_DATA.map((item: any, i: number) => (
                  <div
                    onClick={() => {
                      setSelectedSize(item.size);
                      handleCloseModal();
                    }}
                    key={i}
                    className={`border text-center p-4 rounded-md text-sm cursor-pointer hover:border-blue-600 hover:text-blue-600 ${
                      item.size === selectedSize ? "border-blue-600 text-blue-600" : "text-gray-600"
                    }`}
                  >
                    {item.size}
                  </div>
                ))}
              </div>
            </Modal>
          </ModalPortal>
        )}
      </div>

      {/* <p>opera {id}</p> */}
      <div className="">
        <p className="font-semibold text-xl py-10">이런 상품은 어때요</p>

        <div className="grid xl:grid-cols-5 grid-cols-2 gap-2">
          {data.map((el: any, key: string) => (
            <ProductCard productItem={el} />
          ))}
        </div>
      </div>
    </div>
  );
}
