import PRODUCT_DATA from "../data/data";
import { currency } from "../method";
import { Link } from "react-router-dom";

function ProductCard({ productItem }: any): JSX.Element {
  return (
    <Link to={`/product/${productItem.index}`} className="pb-8">
      <div
        style={{ backgroundImage: `url(${productItem?.images[0]})` }}
        className={`bg-cover bg-center h-[230px] bg-slate-50 rounded-sm bg-animation`}
      ></div>
      <div>
        <p className="font-medium text-sm mt-1">{productItem.brand}</p>
        <p className="text-sm font-light text-gray-600 mt-1">{productItem.productName}</p>
        <p className="font-medium text-sm mt-1">{currency(productItem.price)}원</p>
        <p className="text-xs font-light text-gray-400 mt-1">구매가능</p>
      </div>
    </Link>
  );
}

export default ProductCard;
