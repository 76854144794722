import React, { useState } from "react";
import { currency } from "../method";
import { Link } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import image1 from "../assets/image1.png";
import PRODUCT_DATA from "../data/data";

export default function Home(): JSX.Element {
  const [data, setData] = useState<any>(PRODUCT_DATA);

  return (
    <div className="container mx-auto sm:px-0 px-2">
      <div className="pt-14 pb-8">
        <p className="text-3xl font-medium">신규 상품</p>
      </div>
      <div className="grid xl:grid-cols-5 grid-cols-2 gap-2">
        {data.map((el: any, key: string) => (
          <ProductCard productItem={el} />
        ))}
      </div>

      <div className="pt-14 pb-8">
        <p className="text-3xl font-medium ">추천 상품</p>
      </div>
      <div className="grid xl:grid-cols-5 grid-cols-2 gap-2">
        {data.map((el: any, key: string) => (
          <ProductCard productItem={el} />
        ))}
      </div>
    </div>
  );
}
