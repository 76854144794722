import React, { useContext, useEffect, useState } from "react";
import PRODUCT_DATA from "../data/data";
import { currency } from "../method";
import { useNavigate } from "react-router-dom";
import { IMainContext } from "../interface/interface";
import { MainContext } from "../common/context";

export default function Mypage() {
  const navigate = useNavigate();
  const context = useContext<IMainContext>(MainContext);
  const [tabArr, setTabArr] = ["장바구니", "구매내역", "회원 정보 관리", "배송지 관리", "결제 관리"];
  const [activeTab, setActiveTab] = useState<string>("장바구니");
  const [cartData, setCartData] = useState<any>([]);

  useEffect(() => {
    const storageCart: any = localStorage.getItem("cart");

    setCartData(JSON.parse(storageCart) ? JSON.parse(storageCart) : []);
  }, []);

  const handleClickTab = (str: string) => {
    setActiveTab(str);
  };

  const handleDeleteCartItem = (i: number) => {
    const cart = [...cartData];
    cart.splice(i, 1);
    setCartData(cart);

    localStorage.setItem("cart", JSON.stringify(cart));
  };

  return (
    <div className="container mx-auto sm:px-0 px-2 grid grid-cols-4 mt-12">
      {/* 마이페이지 헤더 */}
      <div className="">
        <p className="font-medium text-2xl">마이페이지</p>

        <p className="text-md mt-10 font-bold">나의 쇼핑</p>
        <p
          onClick={() => handleClickTab("장바구니")}
          className={`text-sm mt-4 ${
            activeTab === "장바구니" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          장바구니
        </p>
        <p
          onClick={() => handleClickTab("구매내역")}
          className={`text-sm mt-2 ${
            activeTab === "구매내역" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          구매내역
        </p>

        {/* 내 정보 */}
        <p className="text-md mt-10 font-bold">내 정보</p>
        <p
          onClick={() => handleClickTab("회원 정보 관리")}
          className={`text-sm mt-4 ${
            activeTab === "회원 정보 관리" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          회원 정보 관리
        </p>
        <p
          onClick={() => handleClickTab("배송지 관리")}
          className={`text-sm mt-2 ${
            activeTab === "배송지 관리" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          배송지 관리
        </p>
        <p
          onClick={() => handleClickTab("결제 관리")}
          className={`text-sm mt-2 ${
            activeTab === "결제 관리" ? "text-black font-bold" : "text-gray-400 font-light"
          } cursor-pointer`}
        >
          결제 관리
        </p>
      </div>
      <div className="grid col-span-3">
        <div>
          <div>
            {activeTab === "장바구니" && (
              <p className="font-medium pb-10 text-xl border-b-black border-b-[2px]">장바구니</p>
            )}
            {activeTab === "구매내역" && (
              <p className="font-medium pb-10 text-xl border-b-black border-b-[2px]">구매내역</p>
            )}
            {activeTab === "회원 정보 관리" && (
              <p className="font-medium pb-10 text-xl border-b-black border-b-[2px]">회원 정보 관리</p>
            )}
          </div>

          {/* cart item box */}
          {activeTab === "장바구니" &&
            cartData.map((item: any, i: number) => (
              <div key={i} className="border-b p-4 border-b flex justify-between items-center">
                <div className="flex">
                  <div
                    style={{ backgroundImage: `url(${item?.images[0]})` }}
                    className="bg-cover bg-center w-[110px] h-[110px] bg-slate-50 rounded-sm flex justify-between items-center"
                  ></div>

                  <div className="flex flex-col justify-between ml-2">
                    <div>
                      <p className="text-sm font-semibold">{item.brand}</p>
                      <p className="mt-2 font-light text-sm">{item.productName}</p>
                    </div>

                    <p className="text-sm font-semibold">{item.size}</p>
                  </div>
                </div>

                <div className="flex">
                  <p className="font-semibold">{currency(item.price)}원</p>

                  <button onClick={() => handleDeleteCartItem(i)} className="ml-8 border text-xs py-1 px-4">
                    삭제
                  </button>
                </div>
              </div>
            ))}
          {activeTab === "구매내역" && (
            <div className="mt-10 text-center">
              <p className="text-sm text-gray-500">현재 구매내역이 없습니다.</p>
            </div>
          )}

          {activeTab === "장바구니" && cartData.length !== 0 && (
            <div className="text-center mt-6">
              <button onClick={() => navigate("/user/cart/buy")} className="text-white bg-black py-2 px-10 text-sm">
                구매하기
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
