import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import image5 from "../assets/image5.png";
import image6 from "../assets/image6.png";
import image7 from "../assets/image7.png";
import image8 from "../assets/image8.png";
import image9 from "../assets/image9.png";
import image10 from "../assets/image10.png";
import image11 from "../assets/image11.png";
import image12 from "../assets/image12.png";
import image13 from "../assets/image13.png";
import image14 from "../assets/image14.png";
import image15 from "../assets/image15.png";
import image16 from "../assets/image16.png";
import image17 from "../assets/image17.png";
import image18 from "../assets/image18.png";

import imageb1 from "../assets/imageb1.jpg";
import imageb2 from "../assets/imageb2.jpg";
import imageb3 from "../assets/imageb3.jpg";
import imageb4 from "../assets/imageb4.jpg";
import imageb5 from "../assets/imageb5.jpg";
import imageb6 from "../assets/imageb6.jpg";
import imageb7 from "../assets/imageb7.jpg";
import imageb8 from "../assets/imageb8.jpg";
import imageb9 from "../assets/imageb9.jpg";
import imageb10 from "../assets/imageb10.jpg";
import imageb11 from "../assets/imageb11.jpg";
import imageb12 from "../assets/imageb12.jpg";
import imageb13 from "../assets/imageb13.jpg";
import imageb14 from "../assets/imageb14.jpg";
import imageb15 from "../assets/imageb15.jpg";
import imageb16 from "../assets/imageb16.jpg";
import imageb17 from "../assets/imageb17.jpg";
import imageb18 from "../assets/imageb18.jpg";
import imageb19 from "../assets/imageb19.jpg";
import imageb20 from "../assets/imageb20.jpg";
import imageb21 from "../assets/imageb21.jpg";
import imageb22 from "../assets/imageb22.jpg";
import imageb23 from "../assets/imageb23.jpg";
import imageb24 from "../assets/imageb24.jpg";
import imageb25 from "../assets/imageb25.jpg";
import imageb26 from "../assets/imageb26.jpg";
import imageb27 from "../assets/imageb27.jpg";
import imageb28 from "../assets/imageb28.jpg";
import imageb29 from "../assets/imageb29.jpg";
import imageb30 from "../assets/imageb30.jpg";
import imageb31 from "../assets/imageb31.jpg";
import imageb32 from "../assets/imageb32.jpg";
import imageb33 from "../assets/imageb33.jpg";
import imageb34 from "../assets/imageb34.jpg";
import imageb35 from "../assets/imageb35.jpg";
import imageb36 from "../assets/imageb36.jpg";

const PRODUCT_DATA = [
  {
    index: 1,
    brand: "조던",
    productName: "에어 조던1 로우 OG SP 트레비스 스캇 미디엄 올리브",
    price: 570000,
    images: [image1, image2],
  },
  {
    index: 2,
    brand: "Asics",
    productName: "(W) 아식스 젤 카야노 14 화이트 미드나잇",
    price: 145000,
    images: [image3, image4],
  },
  {
    index: 3,
    brand: "New Balance",
    productName: "뉴발란스 993 메이드 인 USA 그레이 - D 스탠다드",
    price: 307000,
    images: [image5, image6],
  },
  {
    index: 4,
    brand: "Nike",
    productName: "나이키 에어포스 1 '07 로우 화이트",
    price: 100000,
    images: [image7, image8],
  },
  {
    index: 5,
    brand: "Keen",
    productName: "킨 재스퍼 Rocks SP 아이보리 남성 스니커즈",
    price: 189000,
    images: [image9, image10],
  },
  {
    index: 6,
    brand: "ADIDAS",
    productName: "아디다스 삼바 OG 클라우드 화이트",
    price: 104000,
    images: [image11, image12],
  },
  {
    index: 7,
    brand: "NIKE",
    productName: "나이키 덩크 로우 레트로 그레이 포그",
    price: 78000,
    images: [image13, image14],
  },
  {
    index: 8,
    brand: "UGG",
    productName: "(W) 어그 클래식 미니 2 부츠 체스트넛s",
    price: 181000,
    images: [image15, image16],
  },
  {
    index: 9,
    brand: "On Running",
    productName: "(W) 온 러닝 클라우드 5 올 블랙",
    price: 169000,
    images: [image17, image18],
  },
  {
    index: 10,
    brand: "조던",
    productName: "에어 조던1 로우 OG SP 트레비스 스캇 미디엄 올리브",
    price: 570000,
    images: [image1, image2],
  },
  {
    index: 11,
    brand: "Asics",
    productName: "(W) 아식스 젤 카야노 14 화이트 미드나잇",
    price: 145000,
    images: [image3, image4],
  },
  {
    index: 12,
    brand: "New Balance",
    productName: "뉴발란스 993 메이드 인 USA 그레이 - D 스탠다드",
    price: 307000,
    images: [image5, image6],
  },
  {
    index: 13,
    brand: "Nike",
    productName: "나이키 에어포스 1 '07 로우 화이트",
    price: 100000,
    images: [image7, image8],
  },
  {
    index: 14,
    brand: "Keen",
    productName: "킨 재스퍼 Rocks SP 아이보리 남성 스니커즈",
    price: 189000,
    images: [image9, image10],
  },
  {
    index: 15,
    brand: "ADIDAS",
    productName: "아디다스 삼바 OG 클라우드 화이트",
    price: 104000,
    images: [image11, image12],
  },
  {
    index: 16,
    brand: "NIKE",
    productName: "나이키 덩크 로우 레트로 그레이 포그",
    price: 78000,
    images: [image13, image14],
  },
  {
    index: 17,
    brand: "UGG",
    productName: "(W) 어그 클래식 미니 2 부츠 체스트넛s",
    price: 181000,
    images: [image15, image16],
  },
  {
    index: 18,
    brand: "On Running",
    productName: "(W) 온 러닝 클라우드 5 올 블랙",
    price: 169000,
    images: [image17, image18],
  },
];

const PRODUCT_DATA2 = [
  {
    index: 1,
    brand: "가벽",
    productName: "인테리어 가로 와이드파티션 335",
    price: 54900,
    images: [imageb1, imageb2],
  },
  {
    index: 2,
    brand: "가벽",
    productName: "파티션 안방 셀프 설치 원룸 공간분리 간살 가림막파티션 292mm",
    price: 69900,
    images: [imageb3, imageb4],
  },
  {
    index: 3,
    brand: "가벽",
    productName: "파티션 안방 셀프 설치 원룸 공간분리 간살 가림막파티션 292mm",
    price: 307000,
    images: [imageb5, imageb6],
  },
  {
    index: 4,
    brand: "가벽",
    productName: "파티션 셀프 무타공 설치 공간분리 칸막이 가림막파티션 335mm",
    price: 68500,
    images: [imageb7, imageb8],
  },
  {
    index: 5,
    brand: "파티션",
    productName: "밀키 주방 아일랜드 유리 파티션 400",
    price: 40000,
    images: [imageb9, imageb10],
  },
  {
    index: 6,
    brand: "파티션",
    productName: "베르 아치형 라운드 가림막 공간분리 인테리어 파티션 1200",
    price: 78500,
    images: [imageb11, imageb12],
  },
  {
    index: 7,
    brand: "파티션",
    productName: "아치형 미니 우드칸막이 중간 파티션 1200",
    price: 28900,
    images: [imageb13, imageb14],
  },
  {
    index: 8,
    brand: "파티션",
    productName: "접이식 낮은 타공판 칸막이 인테리어 파티션 1200 1단",
    price: 28900,
    images: [imageb15, imageb16],
  },
  {
    index: 9,
    brand: "파티션",
    productName: "클루 아치형 가림막파티션 칸막이 스탠드 파티션",
    price: 59800,
    images: [imageb17, imageb18],
  },
  {
    index: 10,
    brand: "파티션",
    productName: "펀칭 공간분리 인테리어 칸막이 타공 파티션 1200",
    price: 49800,
    images: [imageb19, imageb20],
  },
  {
    index: 11,
    brand: "테이블",
    productName: "루이 침대 폭 좁은 틈새 수납 슬림 수납장 600X480",
    price: 22900,
    images: [imageb21, imageb22],
  },
  {
    index: 12,
    brand: "테이블",
    productName: "메이 비정형 소파 거실테이블 L",
    price: 79900,
    images: [imageb23, imageb24],
  },
  {
    index: 13,
    brand: "테이블",
    productName: "블랑 좌식 소파 거실 테이블 M",
    price: 58800,
    images: [imageb25, imageb26],
  },
  {
    index: 14,
    brand: "테이블",
    productName: "오벌 거실 소파 사이드테이블",
    price: 55800,
    images: [imageb27, imageb28],
  },
  {
    index: 15,
    brand: "테이블",
    productName: "이든 미니테이블 거실 화분 쇼파 협탁 침대 사이드테이블 S",
    price: 69000,
    images: [imageb29, imageb30],
  },
  {
    index: 16,
    brand: "테이블",
    productName: "침대 옆 미니 타공판 협탁",
    price: 70000,
    images: [imageb31, imageb32],
  },
  {
    index: 17,
    brand: "창호",
    productName: "AL 시스템주방창 E9-ATHSH90 LG샷시 아파트 베란다 샷시교체",
    price: 158000,
    images: [imageb33, imageb34],
  },
  {
    index: 18,
    brand: "창호",
    productName: "PVC시스템창 E9-PLS200 LG샷시 아파트 베란다 샷시교체",
    price: 986000,
    images: [imageb34, imageb35],
  },
];

export default process.env.REACT_APP_COMPANYNAME === "(주식회사) 대운건설" ? PRODUCT_DATA2 : PRODUCT_DATA;
