import React, { useContext, useEffect, useState } from "react";
import Modal from "../components/Modal";
import { useParams } from "react-router-dom";
import { currency } from "../method";
import { ModalPortal } from "../components/ModalPortal";
import { useNavigate } from "react-router-dom";
import box from "../assets/box.png";
import credit from "../assets/credit.png";
import account from "../assets/account.png";
import container from "../assets/container.png";
import PRODUCT_DATA from "../data/data";
import { IMainContext } from "../interface/interface";
import { MainContext } from "../common/context";
import DaumPostcodeEmbed, { useDaumPostcodePopup } from "react-daum-postcode";

const SIZE_DATA = [
  {
    size: "XS",
  },
  {
    size: "S",
  },
  {
    size: "M",
  },
  {
    size: "L",
  },
  {
    size: "XL",
  },
  {
    size: "XXL",
  },
];

export default function Buy() {
  const { id, size }: any = useParams();
  const context = useContext<IMainContext>(MainContext);
  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState<string>(SIZE_DATA[0].size);
  const [data, setData] = useState<any>(PRODUCT_DATA[id - 1]);
  const [checkOb, setCheckOb] = useState<any>({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
  });
  const [mainAddress, setMainAddress] = useState<string>("");
  const [subAddress, setSubAddress] = useState<string>("");
  const [invoice, setInvoice] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const [payments, setPayments] = useState<string>("card");

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleBuyProduct = () => {
    if (!context.isUser) return alert("로그인 후 구매해 주세요.");

    const checkArr = Object.keys(checkOb);
    for (let i in checkArr) {
      if (!checkOb[checkArr[i]]) return alert("이용 약관에 모두 동의해 주세요.");
    }

    alert("결제서비스 준비중입니다.");
  };

  const handleCheckAgreement = (checkIdx: number) => {
    const checkOrder: string = `check${checkIdx}`;

    setCheckOb((prev: any) => {
      return {
        ...prev,
        [checkOrder]: !prev[checkOrder],
      };
    });
  };

  const open = useDaumPostcodePopup();

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setMainAddress(fullAddress);
    // console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <div className="container mx-auto sm:px-0 px-2 ">
      <div className="grid md:grid-cols-2 gap-12 mt-14">
        <div>
          <p className="text-2xl">즉시 구매하기</p>

          <div className="flex mt-6">
            <div className="w-[100px] rounded-sm">
              <div
                style={{ backgroundImage: `url(${data.images[0]})` }}
                className="bg-cover bg-center h-[100px] bg-slate-50 rounded-sm"
              ></div>
            </div>

            <div className="px-[10px] flex flex-col justify-between">
              <div>
                <p className="font-medium text-xs">{data.brand}</p>
                <p className="font-medium text-md mt-1">{data.productName}</p>
              </div>

              <p className="font-medium text-xs">{size}</p>
            </div>
          </div>

          <div className="bg-blue-500 w-full mt-8 rounded-md py-2 text-center">
            <p className="text-white text-sm">즉시구매</p>
          </div>

          <div className="flex justify-between items-end border-b-[4px] border-blue-500 my-[14px] py-[14px]">
            <p className="text-sm text-blue-500 font-semibold">즉시 구매가</p>

            <p className="text-2xl font-semibold text-blue-500">{currency(data.price)}원</p>
          </div>

          <p className="font-semibold text-xl mt-20 mb-4">결제 수단</p>
          <div
            onClick={() => setPayments("card")}
            className={`rounded-md border ${
              payments === "card" && "border-black"
            }  p-4 flex items-center justify-between cursor-pointer`}
          >
            <div className="flex items-center">
              <div className="w-[40px] h-[40px] rounded-full bg-gray-100 flex items-center justify-center">
                <img src={credit} className="w-[24px]" />
              </div>

              <p className="ml-[8px] font-semibold text-sm">카드결제</p>
              <p className="ml-[8px] font-light text-sm"></p>
            </div>

            <p className="text-gray-500 font-light text-sm"></p>
          </div>

          <div
            onClick={() => setPayments("virtualAccount")}
            className={`rounded-md border
              ${payments === "virtualAccount" && "border-black"}
              p-4 flex items-center justify-between cursor-pointer mt-2`}
          >
            <div className="flex items-center">
              <div className="w-[40px] h-[40px] rounded-full bg-gray-100 flex items-center justify-center">
                <img src={account} className="w-[24px]" />
              </div>

              <p className="ml-[8px] font-semibold text-sm">가상계좌로 입금</p>
              <p className="ml-[8px] font-light text-sm"></p>
            </div>

            <p className="text-gray-500 font-light text-sm"></p>
          </div>

          <p className="font-semibold text-xl mt-20 mb-4">배송 방법</p>
          <div className="rounded-md border border-black p-4 flex items-center justify-between cursor-pointer">
            <div className="flex items-center">
              <div className="w-[40px] h-[40px] rounded-full bg-gray-100 flex items-center justify-center">
                <img src={box} className="w-[24px]" />
              </div>

              <p className="ml-[8px] font-semibold text-sm">일반 배송</p>
              <p className="ml-[8px] font-light text-sm">3,000원</p>
            </div>

            <p className="text-gray-500 font-light text-sm">검수 후 배송, 5-7일 내 도착예정</p>
          </div>

          <div className="rounded-md border p-4 flex items-center justify-between cursor-pointer mt-2">
            <div className="flex items-center">
              <div className="w-[40px] h-[40px] rounded-full bg-gray-100 flex items-center justify-center">
                <img src={container} className="w-[24px]" />
              </div>

              <p className="ml-[8px] font-semibold text-sm">센터 보관</p>
              <p className="ml-[8px] font-light text-sm">첫 90일 무료</p>
            </div>

            <p className="text-gray-500 font-light text-sm">배송없이 솔드아웃 센터에 보관</p>
          </div>

          <div className="mt-20 mb-4 py-4 border-b border-black flex justify-between">
            <p className="font-semibold text-xl">배송지 정보</p>

            <button onClick={() => setIsOpen(true)} className="text-white bg-black text-xs px-2 rounded-sm">
              배송지 추가
            </button>
          </div>

          <p className="font-semibold">{invoice.destination}</p>
          <p className="text-sm mt-[4px]">{invoice.name}</p>
          <p className="text-sm mt-[4px]">{invoice.phone}</p>
          <p className="text-sm mt-[4px]">{mainAddress}</p>
          <p className="text-sm mt-[4px]">{subAddress}</p>
        </div>
        <div className="border rounded-xl mt-10 p-8">
          <p className="text-xl font-semibold">최종 주문 정보</p>

          <div className="mt-6 border-b border-t border-black py-[20px]">
            <div className="flex justify-between flex-wrap">
              <p className="text-gray-400 text-sm">즉시 구매가</p>
              <p className="text-black text-sm">{currency(data.price)}원</p>
            </div>
            {/* <div className="flex justify-between flex-wrap mt-1">
              <p className="text-gray-400 text-sm">구매 수수료</p>
              <p className="text-black text-sm">{currency(4785)}원</p>
            </div> */}
            <div className="flex justify-between flex-wrap mt-1">
              <p className="text-gray-400 text-sm">검수비</p>
              <p className="text-black text-sm">무료</p>
            </div>
            <div className="flex justify-between flex-wrap mt-1">
              <p className="text-gray-400 text-sm">배송비</p>
              <p className="text-black text-sm">{currency(3000)}원</p>
            </div>
            <div className="flex justify-between flex-wrap mt-1">
              <p className="text-gray-400 text-sm">즉시 구매가</p>
              <p className="text-black text-sm">{currency(data.price)}원</p>
            </div>
            <div className="flex items-center justify-between flex-wrap mt-3">
              <p className="font-bold text-md">총 결제 금액</p>
              <p className="text-black text-xl font-semibold">{currency(Number(data.price) + 3000)}원</p>
            </div>
          </div>

          <div className="bg-gray-50 mt-10 p-2">
            <div className="flex items-center justify-between p-4 border-b">
              <div onClick={() => handleCheckAgreement(1)} className="font-medium text-sm mr-4 cursor-pointer">
                구매 입찰 또는 즉시 구매를 통해 주문 결제가 완료되면 단순 변심이나 실수에 의한 취소가 불가합니다.
              </div>

              <div className="relative w-[20px] h-[20px] flex-none border border-gray-400 cursor-pointer">
                {checkOb.check1 && (
                  <p className="absolute text-md" style={{ top: -4, right: 2 }}>
                    ✔
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between p-4 border-b">
              <div onClick={() => handleCheckAgreement(2)} className="font-medium text-sm mr-4 cursor-pointer">
                구매하려는 상품이 상품 상세페이지에 등록된 상품 정보와 모두 일치함을 확인하였습니다.
              </div>

              <div className="relative w-[20px] h-[20px] flex-none border border-gray-400 cursor-pointer">
                {checkOb.check2 && (
                  <p className="absolute text-md" style={{ top: -4, right: 2 }}>
                    ✔
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between p-4 border-b">
              <div onClick={() => handleCheckAgreement(3)} className="font-medium text-sm mr-4 cursor-pointer">
                검수 통과된 상품의 교환 및 환불은 불가합니다.
              </div>

              <div className="relative w-[20px] h-[20px] flex-none border border-gray-400 cursor-pointer">
                {checkOb.check3 && (
                  <p className="absolute text-md" style={{ top: -4, right: 2 }}>
                    ✔
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between p-4 border-b">
              <div onClick={() => handleCheckAgreement(4)} className="font-medium text-sm mr-4 cursor-pointer">
                판매자의 판매 거부 및 배송 지연으로 인해 구매가 취소될 수 있습니다.
              </div>

              <div className="relative w-[20px] h-[20px] flex-none border border-gray-400 cursor-pointer">
                {checkOb.check4 && (
                  <p className="absolute text-md" style={{ top: -4, right: 2 }}>
                    ✔
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between p-4 border-b">
              <div onClick={() => handleCheckAgreement(5)} className="font-medium text-sm mr-4 cursor-pointer">
                솔드아웃 구매 약관을 모두 확인했으며 구매에 동의합니다.
              </div>

              <div className="relative w-[20px] h-[20px] flex-none border border-gray-400 cursor-pointer">
                {checkOb.check5 && (
                  <p className="absolute text-md" style={{ top: -4, right: 2 }}>
                    ✔
                  </p>
                )}
              </div>
            </div>
          </div>

          <button onClick={handleBuyProduct} className="w-full bg-black rounded-md mt-4 py-4 text-white">
            {currency(Number(data.price) + 3000)}원 즉시 구매하기
          </button>
        </div>
        {isOpen && (
          <ModalPortal>
            <Modal
              title="배송지 추가"
              onClose={() => {
                setMainAddress("");
                setSubAddress("");
                setInvoice({});
                handleCloseModal();
              }}
              modalContainerStyle={{ width: "34vw" }}
            >
              <div className="p-6">
                <p className="font-semibold text-sm">배송지명</p>
                <input
                  placeholder=""
                  onChange={(e: any) =>
                    setInvoice((prev: any) => {
                      return {
                        ...prev,
                        destination: e.target.value,
                      };
                    })
                  }
                  value={invoice.destination}
                  className="mt-2 bg-gray-50 w-full py-[10px] px-[10px] outline-none"
                />

                <p className="font-semibold text-sm mt-6">수령인</p>
                <input
                  onChange={(e: any) =>
                    setInvoice((prev: any) => {
                      return {
                        ...prev,
                        name: e.target.value,
                      };
                    })
                  }
                  value={invoice.name}
                  placeholder="이름"
                  className="mt-2 bg-gray-50 w-full py-[10px] px-[10px] outline-none"
                />

                <p className="font-semibold text-sm mt-6">수령인 전화번호</p>
                <input
                  onChange={(e: any) =>
                    setInvoice((prev: any) => {
                      return {
                        ...prev,
                        phone: e.target.value,
                      };
                    })
                  }
                  value={invoice.phone}
                  placeholder="전화번호"
                  className="mt-2 bg-gray-50 w-full py-[10px] px-[10px] outline-none"
                />

                <p className="font-semibold text-sm mt-6">배송지 주소</p>
                <div className="mt-2 bg-gray-50 w-full py-[10px] px-[10px] flex justify-between">
                  <div className="flex items-center">
                    <p className="text-xs">{mainAddress}</p>
                  </div>
                  <div>
                    <button onClick={handleClick} className="bg-black rounded-md py-1 px-2 text-white text-xs">
                      주소찾기
                    </button>
                  </div>
                </div>
                <input
                  value={subAddress}
                  onChange={(e: any) => setSubAddress(e.target.value)}
                  placeholder="상세주소"
                  className="mt-2 bg-gray-50 w-full py-[10px] px-[10px] outline-none"
                />

                <button
                  onClick={() => {
                    handleCloseModal();
                  }}
                  className="bg-black text-sm text-white w-full rounded-md py-2 mt-4"
                >
                  배송지 추가하기
                </button>
                {/* <input placeholder="이름" className="mt-2 bg-gray-50 w-full py-[10px] px-[10px] outline-none" /> */}
              </div>
            </Modal>
          </ModalPortal>
        )}
      </div>

      {/* <p>opera {id}</p> */}
    </div>
  );
}
