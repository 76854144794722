import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

import { IMainContext } from "../interface/interface";
import { MainContext } from "../common/context";
import { COMPANYADDRESS, COMPANYADDRESS2, COMPANYINFO, COMPANYLICENSE, COMPANYNAME } from "../env";

export default function Layout(): JSX.Element {
  const context = useContext<IMainContext>(MainContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const storage = localStorage.getItem("isUser");
    if (storage === "user") {
      context.handleStateChange("isUser", true);
    }
  }, []);

  const handleLogout = () => {
    context.handleStateChange("isUser", false);
    context.handleStateChange("userInfo", {});
    localStorage.removeItem("isUser");
    navigate("/login");
  };

  return (
    <>
      <div className="">
        <div className="bg-black pb-3 sm:px-0 px-2">
          <div className="container mx-auto pt-6">
            <div className="flex justify-between items-center">
              <Link to="/">
                <p className="text-2xl font-medium text-white">{process.env.REACT_APP_COMPANYNAME}__</p>
              </Link>
              <div className="flex justify-row items-center">
                {!context.isUser && (
                  <Link to="/login">
                    <p className="text-sm font-light text-gray-500 hover:text-gray-100 hover:font-medium hover:underline mr-5">
                      로그인
                    </p>
                  </Link>
                )}
                {context.isUser && (
                  <p
                    onClick={handleLogout}
                    className="text-sm font-light text-gray-500 cursor-pointer hover:text-gray-100 hover:font-medium hover:underline mr-5"
                  >
                    로그아웃
                  </p>
                )}
                {context.isUser && (
                  <Link to="/user/mypage">
                    <p className="text-sm font-light text-gray-500 hover:text-gray-100 hover:font-medium hover:underline mr-5">
                      마이페이지
                    </p>
                  </Link>
                )}
                <Link to="/notice">
                  <p className="text-sm font-light text-gray-500 hover:text-gray-100 hover:font-medium hover:underline">
                    고객센터
                  </p>
                </Link>
              </div>
            </div>

            <div className="mt-5 flex justify-between items-center">
              <div className="flex justify-row">
                <Link to="/">
                  <div className="border-b-4 border-white pb-2 mr-7">
                    <p className="text-lg font-bold text-white ">추천</p>
                  </div>
                </Link>
                {/* <Link to="/"> */}
                <p
                  onClick={() => alert("카테고리 준비중입니다.")}
                  className="text-lg font-bold text-gray-500 cursor-pointer"
                >
                  카테고리
                </p>
                {/* </Link> */}
              </div>
              <div className="pb-1 flex justify-between pr-2">
                <input
                  autoComplete="off"
                  type="text"
                  id="default-input"
                  placeholder="검색어를 입력해 주세요."
                  className="bg-[#1c1c1c] w-[300px] text-gray-200 text-sm placeholder:text-sm placeholder:text-gray-600  rounded outline-none py-2.5 pl-3"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="w-5 ml-[-35px]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <Outlet />

        <div className="mt-36 mb-10">
          <div className="py-3 border border-gray-200 sm:px-0 px-3">
            <div className="container mx-auto">
              <div className="grid md:grid-cols-2 sm:grid-cols-1">
                <div>
                  <div className="grid md:grid-cols-4 sm:grid-cols-1 ">
                    <Link to="/">
                      <p className="text-sm text-gray-600">회사소개</p>
                    </Link>
                    <Link to="/">
                      <p className="text-sm text-gray-600">이용약관</p>
                    </Link>
                    <Link to="/">
                      <p className="text-sm text-gray-600">개인정보처리방침</p>
                    </Link>
                  </div>
                </div>
                <div>{/* 빈공간 */}</div>
              </div>
            </div>
          </div>

          <div className="container mx-auto sm:px-0 px-3">
            <p className="font-medium text-gray-800 mt-7">{process.env.REACT_APP_COMPANYNAME}</p>

            <p className="text-xs font-light text-gray-500 mt-3">{process.env.REACT_APP_COMPANYINFO}</p>
            <p className="text-xs font-light text-gray-500">{process.env.REACT_APP_COMPANYLICENSE}</p>

            <p className="text-xs font-light text-gray-500 mt-5">{process.env.REACT_APP_COMPANYADDRESS}</p>
            <p className="text-xs font-light text-gray-500">{process.env.REACT_APP_COMPANYADDRESS2}</p>
            <p className="text-xs font-light text-gray-500">호스팅 서비스: 마이크로소프트</p>

            <p className="text-xs font-light text-gray-400 mt-7">
              {process.env.REACT_APP_COMPANYNAME}은(는) 통신판매 중개자로서 통신판매의 당사자가 아니므로 개별판매자가
              등록한 상품정보에 대해서 책임지지 않습니다. 단, 거래과정에서 검수하고 보증하는 내용에 대한 책임은 당사에
              있습니다. 당사는 고객님의 안전거래를 위해 관련 법률에 의거하여 NHN KCP의 에스크로서비스를 적용하고
              있습니다.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
